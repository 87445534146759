import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Navigation from '../components/navigation'
import Transition from '../components/transition'

const Layout = ({ pageTitle, children, location, pageTitleVisible=true, pageWidth="normal"}) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    return (
      <>
        <Helmet meta={[
          { name: 'description', content: 'Sample' },
          { name: 'keywords', content: 'sample, something' },
        ]} titleTemplate={"%s | " + data.site.siteMetadata.title}// Template for all child titles
          defaultTitle={data.site.siteMetadata.title}// Fallback if no child title is set
        >
      </Helmet>
        <a class="sr-only bg-white font-title !no-underline !px-4 !py-3 left-0 top-0 z-1500 focus:block focus:not-sr-only focus:fixed no-print js-smooth-scroll-done" href="#main-content">Skip to content</a>
        <Navigation></Navigation>
          <main id="main-content">
        <Transition location={location}>
          {children}
        </Transition>
        </main>
            </>
  )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
  }
  
export default Layout