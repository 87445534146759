import * as React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'

const Navigation = ({ children }) => {
      const data = useStaticQuery(graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
        }
      `)
      const menuStyle = "scale-100 text-white border-white hover:border-b-2"
    return (
        <div className="bg-brand-dark">
<div className="mx-auto max-w-7xl flex text-white">
<header className="p-4 font-bold transition-all hover:scale-110"><Link className={menuStyle} to="/">{data.site.siteMetadata.title}</Link></header>
<nav>
  <ul className="flex pl-8 p-4 space-x-4">
    <li className="transition-all hover:scale-110"><Link className={menuStyle} to="/about">About</Link></li>
    <li className="transition-all hover:scale-110"><Link className={menuStyle} to="/work">Work</Link></li>
    <li className="transition-all hover:scale-110"><Link className={menuStyle}  to="/blog">Blog</Link></li>
  </ul>
</nav>
  </div>
    </div>
    )
}

export default Navigation

